import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IAdvertisingRuleKeys {
  slider: {};
  slide: {};
}

interface IAdvertisingRuleProps {
  marginBottom?: number;
}

export type IAdvertisingRules = IRuleFn<IAdvertisingRuleKeys, IAdvertisingRuleProps>;

const advertisingRuleFn: IAdvertisingRules = props => {
  const { marginBottom } = props;

  return {
    slider: {
      marginBottom,
    },
    slide: {
      width: 344,
      height: 344,
    },
  };
};

export const advertisingRules = createCachedStyle(advertisingRuleFn);
