import { FC, memo, useEffect } from 'react';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getProductsList } from '../../redux/api/getProductsList';
import { ProductsListTranslator } from '../../redux/translators/productsListTranslator';
import {
  categoriesState,
  selectorCategory,
  selectorSelectedCategory,
  setCategory,
} from '../../redux/slices/categoriesSlice';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { getMetadata } from '../../redux/api/getMetadata';
import { Products } from 'src/components/Products/Products';
import useLoader from 'src/hooks/useLoader';
import { searchState } from 'src/redux/slices/searchSlice';

export const CategoryPage: FC = memo(function CategoryPage() {
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hideLoader } = useLoader();
  const { categoryId } = useParams();
  const selectedCategory = useAppSelector(selectorSelectedCategory);
  const { networkStatus } = useAppSelector(categoriesState);
  const categories = useAppSelector(selectorCategory);
  const { isOpen: searchIsOpen } = useAppSelector(searchState);

  useEffect(() => {
    return () => {
      dispatch(setCategory(null))
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getMetadata());
    }
  }, [networkStatus, dispatch]);

  useEffect(() => {
    const category = categories.find(el => el.category.uuid === categoryId)?.category;
    dispatch(setCategory(category));
  }, [categories, categoryId, dispatch]);

  useEffect(() => {
    const getCategoryProductsFn = async () => {
      const params = ProductsListTranslator.toRequestProductsByCategory(categoryId);
      await dispatch(getProductsList(params)).then(() => {
        hideLoader();
      });
    };
    getCategoryProductsFn();
  }, [dispatch, categoryId, hideLoader]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    if(searchIsOpen) {
      BackButton?.offClickCustom(goBack);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, searchIsOpen, navigate]);

  return <Products title={selectedCategory?.title} />;
});
