import { FC, memo, ReactNode } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductsRules } from './Products.style';
import { Layout } from '../Layout/Layout';
import Header from '../Header/Header';
import { useModalState } from 'src/hooks/useModalState';
import Footer from '../Footer/Footer';
import { Button } from '../UI/Button/Button';
import Container from '../Container/Container';
import { SelectedFilters } from '../SelectedFilters/SelectedFilters';
import ProductCard, { ProductCardSize } from '../ProductCard/ProductCard';
import ModalSorting from '../ModalSorting/ModalSorting';
import ModalFilters from '../ModalFilters/ModalFilters';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useTheme } from 'src/utils/theme/useTheme';
import { productsState } from 'src/redux/slices/productsListSlice';
import Text from '../UI/Text/Text';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { Spinner } from '../UI/Spinner/Spinner';
import { NothingWasFound } from '../NothingWasFound/NothingWasFound';
import { resetFilters, resetSorting } from 'src/redux/slices/filterSlice';
import {resetSearch, searchState} from 'src/redux/slices/searchSlice';
import { getProductsList } from 'src/redux/api/getProductsList';
import { ReactComponent as IconSort } from 'src/assets/icons-clear/sort.svg';
import { ReactComponent as IconFilter } from 'src/assets/icons-clear/filter.svg';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  title?: string;
  subtitle?: string;
}

export const Products: FC<IProps> = memo(function Products(props) {
  const { title, subtitle } = props;
  const [renderSortModal, activeSortModal, openModalSort, closeModalSort] = useModalState();
  const [renderModalFilters, activeModalFilters, openModalFilters, closeModalFilters] =
    useModalState();
  const { selectedFilters, selectedSorting } = useAppSelector(state => state.filters);

  const { css } = useStyle(ProductsRules, {
    sortIsActive: Boolean(selectedSorting.sortingNumber),
    filtersIsActive: selectedFilters.length > 0,
  });
  const { theme } = useTheme();
  const dispatch = useAppDispatch();
  
  // products
  const { products: foundProducts, value: searchValue, networkStatus: searchNetworkStatus } = useAppSelector(searchState);
  const { products, networkStatus } = useAppSelector(productsState);
  const productsList = searchValue ? foundProducts : products
  
  const resetAllFilters = () => {
    dispatch(resetFilters());
    dispatch(resetSorting());
    dispatch(resetSearch());
    dispatch(getProductsList({ 
      filters: [], 
      sorting: 0,
    })) 
  }

  const { t, tPlural } = useTranslation()

  let content: ReactNode;

  if(networkStatus === NetworkStatus.Loading || searchNetworkStatus === NetworkStatus.Loading) {
    content = <div className={css.centerWrapper}><Spinner/></div>;
  }
  else if (productsList.length > 0) {
    content = <>
      {foundProducts.length > 0 && <p className={css.resultStroke}>{tPlural('products.foundItems', foundProducts.length)}</p>}
      {subtitle && <p className={css.subtitle}>{subtitle}</p>}
      {title && <p className={css.title}>{title}</p>}
      <div className={css.grid}>
        {productsList.map(el => {
          return (
            <ProductCard
              key={el.uuid}
              extend={css.card}
              withSlider
              data={el}
              size={ProductCardSize.ADAPTIVE}
            />
          );
        })}
      </div>
    </>
  }
  else {
    content = <div className={css.centerWrapper}><NothingWasFound onClick={resetAllFilters}/></div>;
  }

  return (
    <Layout
      header={
        <Header
          openSortModalFn={openModalSort}
          openModalFitersFn={openModalFilters}
        />
      }
      footer={
        <Footer>
          <Button
            text={t('products.catalog')}
            propsStyles={{
              width: '100%',
              height: 62,
              background: theme.mainColor,
              color: theme.colorWhite,
            }}
            href="/catalog"
            hasGradient={false}

          />
        </Footer>
      }
    >
      <div className={css.wrapper}>
        <div className={css.filtersWrapper}>
          <button className={[css.buttonSort, css.button].join(' ')} onClick={openModalSort}>
            <IconSort/>
          </button>
          <button className={[css.buttonFilter, css.button].join(' ')} onClick={openModalFilters}>
            <IconFilter/>
            <Text text={t('products.filters')} mod='text' fontSize={12} fontWeight={500} lineHeight='16px' />
          </button>
          <SelectedFilters />
        </div>
        <Container extend={css.productsContainer}>
          {content}
        </Container>
        {renderSortModal && <ModalSorting onClose={closeModalSort} active={activeSortModal} />}
        {renderModalFilters && (
          <ModalFilters onClose={closeModalFilters} active={activeModalFilters} />
        )}
      </div>
    </Layout>
  );
});
