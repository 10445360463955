import { createAppAsyncThunk } from '../../../hooks/redux';
import { ICartProductDetail } from '../../slices/cartSlice';
import { CartProduct } from '@teleport/schemas-protobuf/port/v1/port_order_pb';
import { Price } from '@teleport/schemas-protobuf';

export const cartGetDetail = createAppAsyncThunk('cart/cartGetDetail', async (_, thunkAPI): Promise<{productsDetail: ICartProductDetail[], products: CartProduct[], total: Price}> => {
  const cartState = await thunkAPI.extra.portApi.cartGet({});
  const cartProducts = cartState.products;
  const productsDetail = [];
  for (const product of cartProducts) {
    const productDetailResponse = await thunkAPI.extra.portApi.clientCatalogProductDetail({ productUuid: product.productUuid });
    const productDetail = productDetailResponse.product;
    productsDetail.push({
      ...product,
      title: productDetail.title,
      price: productDetail.price,
      image: productDetail.imageUrls[0],
      isFavorite: productDetail.isFavorite})
  }
  return {
    productsDetail: productsDetail,
    products: cartProducts,
    total: cartState.total,
  };
});
