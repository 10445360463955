import { createAppAsyncThunk } from '../../../hooks/redux';
import { CartProduct } from '@teleport/schemas-protobuf/port/v1/port_order_pb';

export const changeQuantityProduct = createAppAsyncThunk('cart/changeQuantityProduct', async (args: {
  action: 'increment' | 'decrement',
  productUuid: string,
  products: CartProduct[],
}, thunkAPI) => {

  const { products } = args;
  const result = await thunkAPI.extra.portApi.cartUpsert({products})

  return {
    total: result.total,
    ...args,
  };
});
