import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ICartItemRuleKeys {
  ci_wrp: {};
  ci_img: {};
  title: {};
  info: {};
  calc: {};
  calc_btn: {};
  info_bottom: {};
  side_btn_dell: {};
  slide_btn: {};
  sw_container: {};
  priceWrapper: {};
  itemPrice: {};
  favoriteButton: {}
}

interface ICartItemProps {
  isFavorite?: boolean;
}

export type ICartItemRules = IRuleFn<ICartItemRuleKeys, ICartItemProps >;

const cartitemRuleFn: ICartItemRules = props => {
  const { colorBlack, colorWhite, colorGrey } = props.theme;
  const { isFavorite } = props;

  return {
    sw_container: {
      padding: '12px 16px',
    },

    ci_wrp: {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '12px',
      color: colorBlack,
    },

    ci_img: {
      width: '100px',
      height: '100px',
      borderRadius: '8px',
      flexShrink: '0',
      '& img': {
        borderRadius: '8px',
        width: '100%',
        objectFit: 'cover',
      },
    },
    title: {
      marginBottom: '4px',
    },
    info: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    calc: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    calc_btn: {
      width: '24px',
      height: '24px',
      borderRadius: '4px',
      background: colorGrey,
      border: 'none',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    info_bottom: {
      marginTop: '12px',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
      gap: '10px',
    },

    slide_btn: {
      width: '44px',
      height: 'auto',
      '& button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        background: colorWhite,
        border: 'none',
        outline: 'none',
      },
    },
    side_btn_dell: {
      '& button': {
        background: colorBlack,
      },
    },
    priceWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
    },
    itemPrice: {
      '@media (max-width: 500px)': {
        maxWidth: 110,
        fontSize: 16,
      },
    },
    favoriteButton: {
      '& svg': {
        flexShrink: 0,
        width: 20,
        height: 20,
        fill: isFavorite ? colorBlack : 'transparent',

        '& .border': {
          fill: colorBlack,
        },
      },
    }
  };
};

export const cartitemRules = createCachedStyle(cartitemRuleFn);
