import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ISelectionRuleKeys {
  section: {};
  header: {};
  headerInner: {};
  title: {};
  slide: {};
}

interface ISelectionRuleProps {
  marginBottom: number;
}

export type ISelectionRules = IRuleFn<ISelectionRuleKeys, ISelectionRuleProps>;

const SelectionRuleFn: ISelectionRules = props => {
  const { marginBottom } = props;

  return {
    section: {
      marginBottom,
    },
    header: {
      marginBottom: 16,
    },
    headerInner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontWeight: 800,
      fontSize: 20,
      lineHeight: '26px',
    },
    slide: {
      width: 140,
    },
  };
};

export const SelectionRules = createCachedStyle(SelectionRuleFn);
