import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductSliderRuleKeys {
  sliderWrapper: {};
  slider: {};
  slide: {};
  pagination: {};
  bullet: {};
  bulletActive: {};
  buttonAddFavorite: {};
  discount: {};
  mainImage: {};
  imageWrapper: {};
  spinnerWrapper: {};
  spinner: {};
  swiper: {};
  image: {};
}

interface IProductSliderRuleProps {
  hasPagination: boolean;
}

export type IProductSliderRules = IRuleFn<IProductSliderRuleKeys, IProductSliderRuleProps>;

const IProductSliderRuleFn: IProductSliderRules = props => {
  const { colorBlack, mainColor, colorWhite } = props.theme;
  const { hasPagination } = props;

  return {
    sliderWrapper: {
      display: 'block',
      marginBottom: hasPagination ? 0 : 12,
    },

    swiper: {
      overflowX: 'auto',
      borderRadius: '8px',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },

    slider: {
      overflow: 'hidden',
      borderRadius: '8px',
    },

    slide: {
      // width: "100%",
    },

    bullet: {
      position: 'relative',
      display: 'block',
      width: '60px',
      height: '60px',
      borderRadius: 8,
      overflow: 'hidden',
      flexShrink: '0',

      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      },
    },

    bulletActive: {
      border: `2px solid ${colorBlack}`,
    },

    pagination: {
      padding: '0 10px',
      paddingTop: 12,
      paddingBottom: 12,
      display: 'flex',
      justifyContent: 'center',
      gap: 8,
      overflowY: 'auto',
    },

    imageWrapper: {
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
      overflow: 'hidden',
      borderRadius: '8px',
      // marginBottom: sliderExist ? 0 : 8,
    },

    spinnerWrapper: {
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
      overflow: 'hidden',
    },

    spinner: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    mainImage: {
      position: 'relative',
      display: 'block',
    },

    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 'auto',
    },

    buttonAddFavorite: {
      zIndex: 2,
      position: 'absolute',
      top: 10,
      right: 10,
    },

    discount: {
      zIndex: 1,
      position: 'absolute',
      padding: '2px 4px',
      bottom: 10,
      right: 10,
      width: 40,
      height: 20,
      borderRadius: 8,
      backgroundColor: mainColor,
      // txt
      textAlign: 'center',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.5%',
      color: colorWhite,
    },
  };
};

export const ProductSliderRules = createCachedStyle(IProductSliderRuleFn);
