import { configureStore } from '@reduxjs/toolkit';
import { searchSlice } from './slices/searchSlice';
import { filterSlice } from './slices/filterSlice';
import { cartSlice } from './slices/cartSlice';
import { mainSlice } from './slices/mainSlice';
import { productDetailSlice } from './slices/productDetailSlice';
import { categoriesSlice } from './slices/categoriesSlice';
import { productsSlice } from './slices/productsListSlice';
import { favouritesSlice } from './slices/favouritesSlice';
import { configSlice } from './slices/configSlice';
import { selectionsSlice } from './slices/selectionsSlice';
import { IConfig } from '../utils/config/config.interface';
import { portApi } from './middleware/portApi';
import {checkoutSlice} from './slices/checkoutSlice';

export const getStore = (config: IConfig) => configureStore({
  reducer: {
    [mainSlice.name]: mainSlice.reducer,
    [searchSlice.name]: searchSlice.reducer,
    [filterSlice.name]: filterSlice.reducer,
    [cartSlice.name]: cartSlice.reducer,
    [favouritesSlice.name]: favouritesSlice.reducer,
    [productDetailSlice.name]: productDetailSlice.reducer,
    [categoriesSlice.name]: categoriesSlice.reducer,
    [productsSlice.name]: productsSlice.reducer,
    [configSlice.name]: configSlice.reducer,
    [selectionsSlice.name]: selectionsSlice.reducer,
    [checkoutSlice.name]: checkoutSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          portApi: portApi(config.portApiUrl),
        },
      },
      serializableCheck: false,
    }),
});

type IStore = ReturnType<typeof getStore>;
export type RootState = ReturnType<IStore['getState']>;
export type AppDispatch = IStore['dispatch'];
