import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ITogglerRuleKeys {
  toggler: {};
  input: {};
}

export type ITogglerRules = IRuleFn<ITogglerRuleKeys>;

const togglerRuleFn: ITogglerRules = props => {
  const { colorDarkGrey, colorWhite, mainColor } = props.theme;

  return {
    toggler: {
      position: 'relative',
      display: 'inline-block',
      borderRadius: '100px',
      backgroundColor: colorDarkGrey,
      width: 40,
      height: 22,
      transitionDuration: '0.2s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'background-color',

      '::before': {
        position: 'absolute',
        left: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        content: '\'\'',
        display: 'block',
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: colorWhite,
        transitionDuration: '0.2s',
        transitionTimingFunction: 'linear',
        transitionProperty: 'left',
      },

      ':has(input:checked)': {
        backgroundColor: mainColor,
        '::before': {
          left: 19,
        },
      },
    },
    input: {
      position: 'absolute',
      zIndex: '-1',
      opacity: 0,
    },
  };
};

export const togglerRules = createCachedStyle(togglerRuleFn);
