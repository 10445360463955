import { register } from 'swiper/element/bundle';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routeConfig } from './providers/AppRouter/routeConfig';
import { useStyle } from './utils/theme/useStyle';
import { appRules } from './App.style';
import 'swiper/css';

// swiper register
register();

const router = createBrowserRouter(routeConfig);

function App() {
  const { css } = useStyle(appRules);
  // перенести сюда тему и попрбовать её сменить
  return (
    <div className={css.app}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
