import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IProductCardRuleKeys {
  card: {};
  productImage: {};
  img: {};
  noimg: {};
  name: {};
  priceWrapper: {};
  oldPrice: {};
  price: {};
  discount: {};
  discountPrice: {};
  buttonAddFavorite: {};
  sliderWrapper: {};
  slider: {};
  slide: {};
  bullet: {};
  bulletHidden: {};
  bulletActive: {};
  pagination: {};
  imgTelegram: {};
}

interface IProductCardRuleProps {
  sliderExist: boolean;
  size: 'small' | 'adaptive';
  telegramCurrency: boolean
}

export type IProductCardRules = IRuleFn<IProductCardRuleKeys, IProductCardRuleProps>;

const productCardRuleFn: IProductCardRules = props => {
  // Размер шрифта и прочие приколы поместить props.theme
  // Ни одного файла css
  // Если нужен какой-то конкрретный стиль например container, то создаём под него компонент
  const { background, mainColor, colorDarkGrey, colorBlack, colorWhite, colorGrey } = props.theme;
  const { sliderExist, size, telegramCurrency } = props;

  return {
    card: {
      display: 'block',
    },
    productImage: {
      position: 'relative',
      width: size === 'small' ? 140 : '100%',
      height: size === 'small' ? 140 : 'initial',
      paddingTop: size === 'small' ? 0 : '100%',
      overflow: 'hidden',
      borderRadius: '8px',
      marginBottom: sliderExist ? 0 : 8,
    },

    img: {
      position: size === 'small' ? 'initial' : 'absolute',
      top: size === 'small' ? 'initial' : 0,
      left: size === 'small' ? 'initial' : 0,
      width: '100%',
      height: size === 'small' ? '100%' : 'auto',
      objectFit: 'cover',
      objectPosition: 'center',
    },

    noimg: {
      position: size === 'small' ? 'initial' : 'absolute',
      top: size === 'small' ? 'initial' : 0,
      left: size === 'small' ? 'initial' : 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: background,

      '& svg': {
        width: 60,
        height: 48,
      },
    },

    name: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '18px',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      'white-space': 'pre-wrap',
    },

    priceWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: '8px',
      alignItems: 'center',
    },

    discountPrice: {
      color: mainColor,
    },

    oldPrice: {
      textDecoration: 'line-through',
      color: colorDarkGrey,
    },

    price: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '-0.14px',
      display: telegramCurrency? 'inline-flex' : '',
      alignItems: telegramCurrency? 'center' : '',
    },

    imgTelegram: {
      position: 'static',
      width: 20,
      objectFit: 'contain'
    },

    discount: {
      zIndex: 1,
      position: 'absolute',
      padding: '2px 4px',
      bottom: 4,
      right: 4,
      width: 40,
      height: 20,
      borderRadius: 8,
      backgroundColor: mainColor,
      // txt
      textAlign: 'center',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '0.5%',
      color: colorWhite,
    },

    buttonAddFavorite: {
      zIndex: 2,
      width: 24,
      height: 24,
      position: 'absolute!important',
      top: 4,
      right: 4,
    },

    sliderWrapper: {
      position: 'relative',
      display: 'block',
    },

    slider: {
      overflowX: 'auto',
      borderRadius: '8px',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },

    slide: {
      overflow: 'hidden',
      borderRadius: '8px',
    },

    bullet: {
      display: 'block',
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      backgroundColor: colorGrey,
    },

    bulletHidden: {
      display: 'block',
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      opacity: 0,
    },

    bulletActive: {
      backgroundColor: colorBlack,
    },

    pagination: {
      marginTop: '4px',
      marginBottom: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: '4px',
    },
  };
};

export const productCardRules = createCachedStyle(productCardRuleFn);
