import { Currency, DeliveryType, Language } from '@teleport/schemas-protobuf';
import { isValidUrl } from '../url/isValidUrl';
import { IConfig } from './config.interface';

// TODO Fix config validation
export function isConfigValid(config: IConfig): boolean {
  let isValid = true;

  if (!isValidUrl(config?.portApiUrl)) {
    console.error('Invalid port api url');
    isValid = false;
  }

  if (
    config.deliveryType !== DeliveryType.SHOWCASE
    && config.deliveryType !== DeliveryType.REQUEST
    && config.deliveryType !== DeliveryType.DIGITAL
  ) {
    console.error('Invalid delivery type');
    console.error(config.deliveryType);
    isValid = false;
  }

  if (!Object.keys(Language).includes(config.language.toUpperCase())) {
    console.error('Invalid language');
    console.error(config.language);
    isValid = false;
  }

  if (!Object.values(Currency).includes(config.currency)) {
    console.error('Invalid currency');
    console.error(config.currency);
    isValid = false;
  }
  if (!config.portBotUsername) {
    console.error('Invalid port bot username');
    isValid = false;
  }

  if (!isValidUrl(config.storeLogo)) {
    console.error('Invalid port store logo url');
    isValid = false;
  }
  
  if (!config.storeName) {
    console.error(config.storeName);
    console.error('Invalid port store name');
    isValid = false;
  }
  if (!config.codeName) {
    console.error(config.codeName);
    console.error('Invalid port store codeName');
    isValid = false;
  }

  // if(!config.storeDescription) {
  //   console.error(config.storeDescription);
  //   console.error('Invalid port store description');
  //   isValid = false;
  // }

  return isValid;
}
