import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { chipRules } from './chip.style';
import { Button } from '../Button/Button';

interface CommonProps {
  id?: string;
  extend?: string;
  text: string;
  removeFn?: () => void;
  attributes?: Record<string, string>;
  // onChange: () => void;
  // checked: boolean;
}

type ConditionalProps =
  | {
      type: 'radio' | 'checkbox';
      name: string;
      value: string;
      onChange: (e: any) => void;
      checked: boolean;
    }
  | { type: 'block'; name?: never; value?: never; onChange?: never; checked?: never };

type IProps = CommonProps & ConditionalProps;

const Chip = function Chip(props: IProps) {
  const { text, name, value, type, checked, attributes, removeFn, onChange } = props;
  const { css } = useStyle(chipRules);

  if (type === 'block') {
    return (
      <div className={css.chip}>
        <span className={css.text}>{text}</span>
        {removeFn && (
          <Button
            onClick={removeFn}
            icon="cross"
            propsStyles={{
              padding: 0,
              width: 12,
              height: 12,
              background: 'transparent',
            }}
          />
        )}
      </div>
    );
  }

  return (
    <label className={css.chip}>
      <input
        type={type}
        className={css.input}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        {...attributes}
      />
      <span className={css.text}>{text}</span>
    </label>
  );
};

export default memo(Chip);
