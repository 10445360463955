interface IFont {
  name: string;
  files: string[];
  options: {
    fontFamily: string;
    fontWeight: number;
  };
}

export const fonts: IFont[] = [
  {
    name: 'Montserrat',
    files: ['/fonts/Montserrat-Regular.ttf'],
    options: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
    },
  },
  {
    name: 'Montserrat',
    files: ['/fonts/Montserrat-Medium.ttf'],
    options: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
    },
  },
  {
    name: 'Montserrat',
    files: ['/fonts/Montserrat-SemiBold.ttf'],
    options: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
    },
  },
  {
    name: 'Montserrat',
    files: ['/fonts/Montserrat-Bold.ttf'],
    options: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
    },
  },
  {
    name: 'Montserrat',
    files: ['/fonts/Montserrat-ExtraBold.ttf'],
    options: {
      fontFamily: 'Montserrat',
      fontWeight: 800,
    },
  },
];
