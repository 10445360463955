import { FC, memo, useEffect, useState } from 'react';
// import Container from 'src/components/Container/Container';
import { Layout } from 'src/components/Layout/Layout';
// import { useStyle } from 'src/utils/theme/useStyle';
// import { productPageRules } from './productPage.style';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import { ProductSlider } from 'src/blocks/Product/components/ProductSlider/ProductSlider';
import { ProductMainInfo } from 'src/blocks/Product/components/ProductMainInfo/ProductMainInfo';
import Wrapper from 'src/components/Wrapper/Wrapper';
import { useStyle } from 'src/utils/theme/useStyle';
// import { ProductModificators } from 'src/blocks/Product/components/ProductModificators/ProductModificators';
import Container from 'src/components/Container/Container';
import { ProductDescription } from 'src/blocks/Product/components/ProductDescription/ProductDescription';
import { useTheme } from 'src/utils/theme/useTheme';
import { productPageRules } from './productPage.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getProductDetail, productState } from '../../redux/slices/productDetailSlice';
import useLoader from 'src/hooks/useLoader';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { cartState, resetNetworkStatusCardAppend } from '../../redux/slices/cartSlice';
import Selection from 'src/components/Selection/Selection';
import { selectionsProductPage } from '../../redux/slices/selectionsSlice';
import { configState } from 'src/redux/slices/configSlice';
import { useTelegram } from 'src/utils/telegramProvider';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import Header from '../../components/Header/Header';
import { productToCardAppend } from '../../redux/api/cart/productToCardAppend';
import { DeliveryType } from '@teleport/schemas-protobuf';

export const ProductPage: FC = memo(function ProductPage() {
  const { css } = useStyle(productPageRules);
  const { theme } = useTheme();
  const { showLoader, hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  const { product, networkStatus } = useAppSelector(productState);
  const productPageSelections = useAppSelector(selectionsProductPage);
  const { networkStatusCardAppend } = useAppSelector(cartState);
  const { config } = useAppSelector(configState);
  const { webApp } = useTelegram();
  const { t } = useTranslation()
  const [productIsInCartState, setProductIsInCartState] = useState(false)

  useEffect(() => {
    if (networkStatusCardAppend === NetworkStatus.Done) {
      setProductIsInCartState(true)
    }
    return () => {
      dispatch(resetNetworkStatusCardAppend())
    }
  }, [networkStatusCardAppend, dispatch])

  function openProductLink() {
    if (product.deliveryAction.case === 'deliveryLink') {
      webApp.openLink(product.deliveryAction.value);
    }
  }

  // Заказать
  function toOrder() {
    navigate(`/make-single-order/${productId}`);
    console.info('Order');
  }

  // Оформить заказ
  function makeOrder() {
    console.info('Place an order');
    navigate(`/make-single-order/${productId}`);
  }

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    dispatch(getProductDetail(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, showLoader]);

  if (!product) {
    return null;
  }

  const addProductToCartFn = () => {
    dispatch(productToCardAppend({productUuid: productId}))
  };

  return (
    <Layout
      header={<Header />}
      footer={
        <Footer>
          {config.cartEnabled ? (
            <Button
              text={productIsInCartState ? t('productPage.addMore') : t('productPage.addToCart')}
              onClick={addProductToCartFn}
              propsStyles={{ width: '100%', height: 62 }}
              disabled={networkStatusCardAppend === NetworkStatus.Loading}
            />
          ) : config.deliveryType === DeliveryType.DIGITAL ? (
            <Button
              text={t('productPage.checkout')}
              propsStyles={{ width: '100%', height: 62 }}
              onClick={makeOrder}
            />
          ) : config.deliveryType === DeliveryType.PHYSICAL ? (
            <Button
              text={t('productPage.checkout')}
              propsStyles={{ width: '100%', height: 62 }}
              onClick={makeOrder}
            />
          ) : config.deliveryType === DeliveryType.REQUEST ? (
            <Button text={t('productPage.order')} propsStyles={{ width: '100%', height: 62 }} onClick={toOrder} />
          ) : config.deliveryType === DeliveryType.SHOWCASE ? (
            // Добавить prop revertIcon
            <Button
              text={t('productPage.proceedToPurchase')}
              propsStyles={{ width: '100%', height: 62 }}
              icon="arrow-top-right"
              iconAtTheEnd={true}
              onClick={openProductLink}
            />
          ) : null}
        </Footer>
      }
    >
      <div className={css.productInner}>
        <ProductSlider productData={product} />
        <Wrapper extend={css.wrapperInfo} propsStyles={{ paddingBottom: 0 }}>
          <Container>
            <ProductMainInfo productData={product} />
            {/* <ProductModificators /> */}
            {product.description && <ProductDescription text={product.description} />}
          </Container>
          {productPageSelections.length > 0 && (
            <Wrapper
              propsStyles={{
                // TODO fixme
                // borderRadius: '16px 16px 0px 0px',
                backgroundColor: theme.background,
                paddingBottom: '20px',
              }}
              extend={css.wrapperCollections}
            >
              {productPageSelections.map(selection => (
                <Selection selectionData={selection} key={selection.title} location="productPage" />
              ))}
            </Wrapper>
          )}
        </Wrapper>
      </div>
    </Layout>
  );
});
