import { createSlice } from '@reduxjs/toolkit';
import { ProductTranslator } from '../translators/productTranslator';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { RootState } from '../store';
import { createAppAsyncThunk } from '../../hooks/redux';
import { productToFavoriteBind } from './favouritesSlice';
import { ClientCatalogProductFull } from '@teleport/schemas-protobuf';

interface IProductDetail {
  product: ClientCatalogProductFull | null;
  networkStatus: NetworkStatus;
}

const initialState: IProductDetail = {
  product: null,
  networkStatus: NetworkStatus.None,
};

export const getProductDetail = createAppAsyncThunk(
  'product/detail',
  async (productUuid: string, thunkAPI) => {
    const result = await thunkAPI.extra.portApi.clientCatalogProductDetail({ productUuid });
    return ProductTranslator.fromStatusResponse(result);
  },
);

export const productDetailSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getProductDetail.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductDetail.fulfilled, (state, action) => {
      state.product = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductDetail.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(productToFavoriteBind.fulfilled, (state, action) => {
      if (state.product) state.product.isFavorite = action.payload.isFavourite;
    });
  },
});

export const productState = (state: RootState) => state[productDetailSlice.name];
