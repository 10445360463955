import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IInfoBlockRuleKeys {
  wrapper: {};
  content: {};
  title: {};
  text: {};
  button: {};
}

export interface IInfoBlockRuleProps {
  accentColor: boolean;
}

export type IInfoBlockRules = IRuleFn<IInfoBlockRuleKeys, IInfoBlockRuleProps>;

const InfoblockRuleFn: IInfoBlockRules = props => {
  const { colorWhite, colorBlack, mainColor } = props.theme;
  const { accentColor } = props;

  return {
    wrapper: {
      position: 'relative',
      background: accentColor ? mainColor : 'transparent',
      padding: '20px',
      borderRadius: '16px',

      '::before': {
        content: '""',
        borderRadius: '12px',
        display: 'block',
        position: 'absolute',
        inset: 0,
        // zIndex: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255, 0) 25%, rgba(255,255,255,0.2246393557422969) 50%, rgba(255,255,255,0.2046393557422969) 100%);',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '20px',
    },
    button: {
      // zIndex: 1,
    },

    title: {
      color: accentColor ? colorWhite : colorBlack,
      display: 'block',
      marginBottom: 8,
    },
    text: {
      color: accentColor ? colorWhite : colorBlack,
      display: 'block',
    },
  };
};

export const InfoblockRules = createCachedStyle(InfoblockRuleFn);
