import { createAppAsyncThunk } from '../../../hooks/redux';
import { CartProduct } from '@teleport/schemas-protobuf/port/v1/port_order_pb';
import { Price } from '@teleport/schemas-protobuf';

export const cartGet = createAppAsyncThunk('cart/cartGet', async (_, thunkAPI): Promise<{products: CartProduct[], total: Price}> => {
  const cartGetResponse = await thunkAPI.extra.portApi.cartGet({});
  return {
    products: cartGetResponse.products,
    total: cartGetResponse.total
  };
});

