import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { bannerRules } from './banner.style';
import { useTelegram } from 'src/utils/telegramProvider';

interface IProps {
  image: string;
  url: string;
}

export interface IStyles {
  background: string;
}

export const Banner = memo(function Banner(props: IProps) {
  const { image, url } = props;
  const { css } = useStyle(bannerRules, { background: image });
  const { webApp } = useTelegram();

  function handleButtonClick() {
    webApp.openLink(url, { try_instant_view: true });
  }

  return <button onClick={handleButtonClick} className={css.banner} />;
});
