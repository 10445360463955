import { ChangeEvent, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { togglerRules } from './toggler.style';

interface IProps {
  id?: string;
  extend?: string;
  name: string;
  value: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Toggler = function Toggler(props: IProps) {
  const { name, value, id, checked, onChange } = props;
  const { css } = useStyle(togglerRules);

  return (
    <label className={css.toggler}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        className={css.input}
        onChange={onChange}
      />
    </label>
  );
};

export default memo(Toggler);
