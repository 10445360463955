import { CartProduct } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../hooks/redux';
import {CheckoutTranslator} from '../translators/checkoutTranslator';

export interface IDirectCheckoutParams {
  product: Omit<CartProduct, 'fromBinary' | 'fromJson' | 'fromJsonString' | 'equals' | 'runtime' | 'typeName' | 'fields' | 'clone' | 'toBinary' | 'toJson' | 'toJsonString' | 'getType'>;
  formData: {
    name: string,
    phone: string,
    email?: string,
  }
}

export const directCheckout = createAppAsyncThunk('checkout/directCheckout', async (params: IDirectCheckoutParams, thunkAPI) => {
  const state = thunkAPI.getState();
  const deliveryType = state.config.config.deliveryType;
  const requestData = CheckoutTranslator.toCheckoutRequest(params, deliveryType)
  return await thunkAPI.extra.portApi.directCheckout(requestData);
});


