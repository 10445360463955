import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { StoreInfoRules } from './StoreInfo.style';
import Text from '../UI/Text/Text';
import { useAppSelector } from 'src/hooks/redux';
import { configState } from 'src/redux/slices/configSlice';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../providers/AppRouter/routeConfig';

// interface IProps {}

export const StoreInfo: FC = memo(function StoreInfo(props) {
  const {} = props;
  const { css } = useStyle(StoreInfoRules);
  const { config } = useAppSelector(configState);
  const {storeLogo, storeDescription, storeName} = config

  return <Link className={css.wrapper} to={RoutePath.main}>
    <img src={storeLogo} className={css.storeAvatar} />
    <div className={css.info}>
      <Text
        mod='title'
        text={storeName}
        fontWeight={600}
        fontSize={14}
        lineHeight='16px'
        whiteSpace='nowrap'
        extend={css.name}
      />
      <Text
        mod='title'
        text={storeDescription}
        fontWeight={400}
        fontSize={12}
        lineHeight='14px'
        extend={css.description}
        whiteSpace='nowrap'
      />
    </div>
  </Link>;
});
