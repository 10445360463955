import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductSliderRules } from './productSlider.style';
import { FavoriteButton } from 'src/components/UI/FavoriteButton/FavoriteButton';
import { ClientCatalogProductFull } from '@teleport/schemas-protobuf';
import { ImageComponent } from 'src/components/ImageComponent/ImageComponent';
import { checkIfImageExists } from 'src/utils/checkIfImageExists';
import { Spinner } from 'src/components/UI/Spinner/Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { favouritesState, productToFavoriteBind, productToFavoriteUnbind } from 'src/redux/slices/favouritesSlice';

interface IProductSliderProps {
  productData: ClientCatalogProductFull;
}

async function imagesIsExist(images: string[]) {
  const existingImages = [];

  for (let i = 0; i < images.length; i++) {
    const result = await checkIfImageExists(images[i]);

    if (result) {
      existingImages.push(images[i]);
    } else {
      existingImages.push('');
    }
  }

  return existingImages;
}

export const ProductSlider: FC<IProductSliderProps> = memo(function ProductSlider(props) {
  const { productData } = props;
  const {
    uuid: productUuid,
    imageUrls: images,
    price: { discountInPercent },
  } = productData;

  const customPaginationRef = useRef(null);
  const [showSliderLoader, setShowSliderLoader] = useState(true);
  const [bulletImages, setBulletImages] = useState([]);
  const hasPagination = images.length > 1;
  const sliderWrapperRef = useRef(null);
  const dispatch = useAppDispatch();
  const { productUuids } = useAppSelector(favouritesState);

  // styles
  const { css } = useStyle(ProductSliderRules, {
    hasPagination,
  });

  // swiper settings
  useEffect(() => {
    async function settingSlider() {
      setShowSliderLoader(true); // Показать загрузчик
      setBulletImages([]); // Очистить старые превьюшки
      const bulletImages = await imagesIsExist(images);
      setBulletImages(bulletImages);
      setShowSliderLoader(false);
    }
    settingSlider();
  }, [images]);

  const addOrDellFavourites = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (productUuids.includes(productUuid)) {
      dispatch(
        productToFavoriteUnbind({
          productUuids: [productUuid],
          currentUuid: productUuid,
          isFavourite: false,
        }),
      );
    } else {
      dispatch(
        productToFavoriteBind({
          productUuids: [...productUuids, productUuid],
          currentUuid: productUuid,
          isFavourite: true,
        }),
      );
    }
  };

  if (images.length === 0) {
    return null;
  }

  return (
    <div className={css.sliderWrapper} ref={sliderWrapperRef}>
      {showSliderLoader ? (
        <div className={css.spinnerWrapper}>
          <Spinner extend={css.spinner} />
        </div>
      ) : (
        <>
          <div className={css.mainImage}>
            <Swiper
              spaceBetween={8}
              slidesPerView={1}
              pagination={{
                el: customPaginationRef.current,
                bulletClass: css.bullet,
                bulletActiveClass: css.bulletActive,
                renderBullet(index, className) {
                  return `<div class="${className}"><img src="${bulletImages[index] ? bulletImages[index] : '/images/stub.svg'}"></div>`;
                },
                clickable: true,
              }}
              className={css.swiper}
            >
              {images.map(el => (
                <SwiperSlide className={css.slide} key={el}>
                  <div className={css.imageWrapper}>
                    <ImageComponent src={el} alt="product" addClass={css.image} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <FavoriteButton
              isFavorite={productUuids.includes(productUuid)}
              extend={css.buttonAddFavorite}
              onClick={addOrDellFavourites}
            />
            {Boolean(discountInPercent) && (
              <div className={css.discount}>-{discountInPercent}%</div>
            )}
          </div>
        </>
      )}
      {hasPagination && <div ref={customPaginationRef} className={css.pagination} />}
    </div>
  );
});
