import { configState } from 'src/redux/slices/configSlice';
import { useAppSelector } from './redux';
import { Currency } from '@teleport/schemas-protobuf';

const useCurrency = () => {
  const { config } = useAppSelector(configState);

  switch (config.currency) {
    case Currency.AED:
      return 'د.إ';
    case Currency.AMD:
      return '֏';
    case Currency.ARS:
      return '$';
    case Currency.AZN:
      return '₼';
    case Currency.BDT:
      return 'টাকা';
    case Currency.BRL:
      return 'R$';
    case Currency.BYN:
      return 'Br';
    case Currency.COP:
      return 'Col$';
    case Currency.EGP:
      return '£';
    case Currency.ETB:
      return 'Br';
    case Currency.EUR:
      return '€';
    case Currency.GEL:
      return '₾';
    case Currency.IDR:
      return 'Rp';
    case Currency.INR:
      return '₹';
    case Currency.KES:
      return 'KSh';
    case Currency.KGS:
      return 'лв';
    case Currency.KZT:
      return '₸';
    case Currency.LKR:
      return 'රු';
    case Currency.NGN:
      return '₦';
    case Currency.PHP:
      return '₱';
    case Currency.PKR:
      return 'Rs';
    case Currency.RUB:
      return '₽';
    case Currency.THB:
      return '฿';
    case Currency.TRY:
      return '₺';
    case Currency.UAH:
      return '₴';
    case Currency.UGX:
      return '/=';
    case Currency.USD:
      return '$';
    case Currency.UZS:
      return 'sum';
    case Currency.USDT:
      return 'USD₮';
    case Currency.TON:
      return 'TON';
    case Currency.BTC:
      return '₿';
    case Currency.NOT:
      return 'NOT';
    case Currency.XTR:
      return 'stars';
    default:
      return '';
  }
};

export default useCurrency;
