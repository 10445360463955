import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPromoCode } from 'src/types/cart';
import { RootState } from '../store';
import { ClientCatalogProductFull, Price } from '@teleport/schemas-protobuf';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { createAppAsyncThunk } from '../../hooks/redux';
import { cartGet } from '../api/cart/cartGet';
import type { Modifier } from '@teleport/schemas-protobuf/port/v1/port_modifiers_pb';
import { cartGetDetail } from '../api/cart/cartGetDetail';
import { CartProduct } from '@teleport/schemas-protobuf/port/v1/port_order_pb';
import { productToCardAppend } from '../api/cart/productToCardAppend';
import { removeProductFromCart } from '../api/cart/removeProductFromCart';
import { changeQuantityProduct } from '../api/cart/changeQuantityProduct';
import { productToFavoriteBind, productToFavoriteUnbind } from './favouritesSlice';
import { cartCheckout } from '../api/cart/cartCheckout';

export interface ICartProductDetail {
  productUuid: string;
  quantity: number;
  chosenModifiers: Modifier[];
  title: string;
  price: Price;
  image: string;
  isFavorite: boolean;
}

interface CartState {
  products: CartProduct[];
  productsDetail: ICartProductDetail[]
  minPrice: number;
  total: Price;
  promoCode?: IPromoCode;
  networkStatus: NetworkStatus;
  networkStatusDetail: NetworkStatus;
  networkStatusCardAppend: NetworkStatus,
  networkStatusRemoveProduct: NetworkStatus;
  networkStatusChangeQuantityProduct: NetworkStatus;
}

// Асинхронный thunk для получения карточек товаров из localStorage
export const getCartProducts = createAppAsyncThunk<ICartProductDetail[]>(
  'products/fetchProducts',
  async () => {
    const productsJson = localStorage.getItem('products');
    if (productsJson) {
      return JSON.parse(productsJson);
    }
    return [];
  },
);

const initialState: CartState = {
  promoCode: undefined,
  minPrice: 0,
  products: [],
  productsDetail: [],
  total: new Price({
    amount: BigInt(0),
    oldAmount: BigInt(0),
    discountEnabled: false,
    discountInPercent: 0
  }),
  networkStatus: NetworkStatus.None,
  networkStatusDetail: NetworkStatus.None,
  networkStatusCardAppend: NetworkStatus.None,
  networkStatusRemoveProduct: NetworkStatus.None,
  networkStatusChangeQuantityProduct: NetworkStatus.None,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartMinPrice: (state, action: PayloadAction<number>) => {
      if (action.payload > 0) state.minPrice = action.payload
    },
    changeCartCount: (
      state,
      action: PayloadAction<{
        cartItemId: number | string;
        increase: boolean;
      }>,
    ) => {
      const { cartItemId, increase } = action.payload;
      const currentProduct = state.products.find(product => product.productUuid === cartItemId);
      if (currentProduct) currentProduct.quantity += increase ? 1 : -1;
    },
    dellFromCart: (state, action: PayloadAction<{ cartItemId: number | string }>) => {
      state.products = state.products.filter(el => el.productUuid !== action.payload.cartItemId);
    },
    addProductToCart: (state, action: PayloadAction<ClientCatalogProductFull>) => {
      const product = action.payload;
      const currentProduct = state.products.find(pr => pr.productUuid === product.uuid);
      if (currentProduct) {
        currentProduct.quantity += 1;
      } else {
        //TODO => разобратьс с эни
        const tempCartItem: any = {
          ...product,
          count: 1,
        };
        state.products.push(tempCartItem);
      }
    },
    setPromoCode: (state, action: PayloadAction<IPromoCode | undefined>) => {
      state.promoCode = action.payload
        ? {
          name: action.payload.name,
          discount: action.payload.discount,
        }
        : undefined;
    },
    resetNetworkStatusCardAppend: state => {
      state.networkStatusCardAppend = NetworkStatus.None
    }
  },
  extraReducers: builder => {
    builder.addCase(cartGetDetail.pending, state => {
      state.networkStatusDetail = NetworkStatus.Loading;
    });
    builder.addCase(cartGetDetail.fulfilled, (state, action) => {
      state.productsDetail = action.payload.productsDetail;
      state.products = action.payload.products;
      state.total = action.payload.total;
      state.networkStatusDetail = NetworkStatus.Done;
    });
    builder.addCase(cartGetDetail.rejected, state => {
      state.networkStatusDetail = NetworkStatus.Failed;
    });

    builder.addCase(cartGet.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(cartGet.fulfilled, (state, action) => {
      state.products = action.payload.products;
      state.total = action.payload.total;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(cartGet.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(productToCardAppend.pending, state => {
      state.networkStatusCardAppend = NetworkStatus.Loading;
    });
    builder.addCase(productToCardAppend.fulfilled, (state, action) => {
      state.networkStatusCardAppend = NetworkStatus.Done;
      const appendedProduct = action.payload;
      const currentItem = state.products.find(el => el.productUuid === appendedProduct.productUuid);
      if(currentItem) {
        currentItem.quantity += 1;
      } else {
        state.products.push(appendedProduct)
      }
    });
    builder.addCase(productToCardAppend.rejected, state => {
      state.networkStatusCardAppend = NetworkStatus.Failed;
    });

    builder.addCase(removeProductFromCart.fulfilled, (state, action) => {
      const { products, deletedUuid, total } = action.payload;
      state.networkStatusRemoveProduct = NetworkStatus.Done;
      state.products = products;
      state.productsDetail = state.productsDetail.filter(el => el.productUuid !== deletedUuid);
      state.total = total;
    });
    
    builder.addCase(changeQuantityProduct.pending, state => {
      state.networkStatusChangeQuantityProduct = NetworkStatus.Loading
    });
    builder.addCase(changeQuantityProduct.rejected, state => {
      state.networkStatusChangeQuantityProduct = NetworkStatus.Failed
    });
    builder.addCase(changeQuantityProduct.fulfilled, (state, action) => {
      state.networkStatusChangeQuantityProduct = NetworkStatus.Done
      const { products, productUuid, action: quantityAction, total } = action.payload;
      state.products = products;
      const product = state.productsDetail.find(element => element.productUuid === productUuid);
      if (!product) return;
      if(quantityAction === 'increment') {
        product.quantity++;
      } else if(quantityAction === 'decrement') {
        if (product.quantity - 1 < 1) return;
        product.quantity--;
      }
      state.total = total;
    });

    builder.addCase(productToFavoriteBind.fulfilled, (state, action) => {
      const product = state.productsDetail.find(el => el.productUuid === action.payload.currentUuid);
      if(!product) return;
      product.isFavorite = true;
    })

    builder.addCase(productToFavoriteUnbind.fulfilled, (state, action) => {
      const product = state.productsDetail.find(el => el.productUuid === action.payload.currentUuid);
      if(!product) return;
      product.isFavorite = false;
    })

    builder.addCase(cartCheckout.fulfilled, () => {
      return initialState
    })

  },
});

export const { changeCartCount, dellFromCart, setPromoCode, addProductToCart, setCartMinPrice, resetNetworkStatusCardAppend } = cartSlice.actions;
export const cartState = (state: RootState) => state[cartSlice.name];
