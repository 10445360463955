import { createAppAsyncThunk } from '../../../hooks/redux';
import { CheckoutTranslator } from '../../translators/checkoutTranslator';

export interface ICartCheckoutParams {
  formData: {
    name: string,
    phone: string,
    email?: string,
  }
}

export const cartCheckout = createAppAsyncThunk('checkout/cartCheckout', async (params: ICartCheckoutParams, thunkAPI) => {
  const state = thunkAPI.getState();
  const deliveryType = state.config.config.deliveryType;
  const requestData = CheckoutTranslator.toCheckoutRequest(params, deliveryType)
  return await thunkAPI.extra.portApi.cartCheckout(requestData);
});
