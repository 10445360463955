import { useState } from 'react';

export const useModalState = (): [boolean, boolean, () => void, () => void] => {
  const [render, setRender] = useState(false);
  const [active, setActive] = useState(false);

  function openModal() {
    setRender(true);
    setTimeout(() => {
      setActive(true);
    }, 100);
  }

  function closeModal() {
    setActive(false);
    setTimeout(() => {
      setRender(false);
    }, 300);
  }

  return [render, active, openModal, closeModal];
};
